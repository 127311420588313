
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import FAQActivitiesTimelineItem from "./FAQActivitiesTimelineItem.vue";
interface FAQActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "FAQActivitiesView",
  components: { FAQActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): FAQActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          ADD_FAQ_FAILURE,
          ADD_FAQ_SUCCESS,
          UPDATE_FAQ_FAILURE,
          UPDATE_FAQ_SUCCESS,
          DELETE_FAQ_SUCCESS,
          DELETE_FAQ_FAILURE
          `.replace(/\s/g, ""),
        activityType: "FAQActivity"
      };
    }
  }
});
