<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div v-html="formattedActivityText" class=""></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    FAQ: {
      type: Object,
      required: false
    }
  },
  methods: {
    async generateReadableUpdateFAQ() {
      let contentHtml = ``;
      for (let update of this.updates) {
        contentHtml = this.generateRegularActivityString(contentHtml, update);
      }
      this.setReadableUpdateProps(contentHtml);
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_FAQ_SUCCESS":
            this.generateReadableGeneric({
              content: "added a new FAQ.",
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_FAQ_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to add an FAQ.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_FAQ_SUCCESS":
            await this.generateReadableUpdateFAQ();
            break;
          case "UPDATE_FAQ_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update FAQ.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_FAQ_SUCCESS":
            this.generateReadableGeneric({
              content: "deleted an FAQ.",
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_FAQ_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete FAQ.",
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
